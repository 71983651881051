import {
  useStaticQuery,
  graphql
} from 'gatsby';

const allPages = graphql`
  query AllWpPages {
    allWpPage {
      nodes {
        uri
        title
        slug
        parentId
      }
    }
  }
`;

const useAllPages = () => {
  const {
    allWpPage: {
      nodes
    },
  } = useStaticQuery(allPages);

  return nodes;
};

export default useAllPages;
