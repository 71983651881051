import React from 'react';
import Logo from 'assets/logos/logo-light.inline.svg';

const Details = ({ data }) => {
  return (
    <div className="sm:flex xl:block">
      <p className="flex-none">
        <Logo />
      </p>

      <p className="text-white pt-8 sm:pt-0 sm:pl-12 lg:pl-32 xl:pl-0 xl:pt-12 text-opacity-40 tracking-wider">
        {data}
      </p>
    </div>
  );
};

export default Details;
