import React from 'react';
import classnames from 'classnames';

import Link from './Link';
import ButtonElement from './Button';
import './styles.scss';

export const Button = ({ href = null, className, ...props }) => {
  if (href) {
    return <Link
      href={href}
      {...props}
      className={classnames('h-16 px-12', className)}
    />
  } else {
    return <ButtonElement
      {...props}
      className={classnames('h-16 px-12', className)}
    />
  }
};

export const ButtonSmall = ({ href = null, className = '', ...props }) => {
  if (href) {
    return <Link href={href}
      {...props}
      className={classnames('text-sm h-12 px-12', className)}
    />
  } else {
    return <ButtonElement
      {...props}
      className={classnames('text-sm h-12 px-12', className)}
    />
  }
};

export const ButtonExtraSmall = ({ href = null, className = '', ...props }) => {
  if (href) {
    return <Link
      href={href}
      {...props}
      className={classnames('text-xs h-9 px-3 sm:px-6', className)}
    />
  } else {
    return <ButtonElement
      {...props}
      className={classnames('text-xs h-9 px-3 sm:px-6', className)}
    />
  }
};