import React from 'react';
import classnames from 'classnames';
import Link from 'components/Link';

const LinkElement = ({ mod = "", className, children, ...props }) =>
  <Link
    className={classnames('btn', mod, className)}
    {...props}><span className="leading-none">{children}</span></Link>

export default LinkElement;
