import React from 'react';
import classnames from 'classnames';

import ItemNavColumn from './ItemNavColumn';

import useAllMenus from 'hooks/graphql/useAllMenus';

import { column } from './styles.module.scss';

// import useCurrentNav from 'hooks/useCurrentNav';

const Navigtion = () => {
  // const caurrentNavigation = useCurrentNav();

  // const navigation = caurrentNavigation.footer?.menuItems.nodes || null;

  // if (!navigation) return <></>;

  const allNavs = useAllMenus();

  const footerNav = allNavs.filter((nav) => nav.name === 'Global footer menu');

  const currentNav = footerNav[0].menuItems.nodes;

  return (
    <div>
      <nav className="text-white uppercase">
        <ul className={classnames('gap-10', column)}>
          {currentNav.map((link) => (
            <ItemNavColumn
              key={link.label + link.url}
              link={link}
              className={classnames(
                'aspect-w-16 aspect-h-16',
                link.label === 'Brain Cancer' || link.label === 'Kidney cancer'
                  ? 'opacity-40 pointer-events-none'
                  : '',
              )}
            />
          ))}
        </ul>

        {/* <div className="space-y-5 lg:w-52 2xl:w-56">
          <p className="text-white text-opacity-40">Visit our other guides:</p>
          <p>
            <Link to="#" className="uppercase text-white hover:text-opacity-70">
              Skin Cancer
            </Link>
          </p>
          <p className="text-white text-opacity-40 disabled pointer-events-none">
            <Link to="#" aria-disabled>
              Blood Cancer
              <sup className="capitalize"> (Coming Soon)</sup>
            </Link>
          </p>
        </div> */}
      </nav>
    </div>
  );
};

export default Navigtion;
