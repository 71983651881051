import {
  graphql,
  useStaticQuery
} from 'gatsby';

const useOptions = () => {
  const {
    allWp: {
      nodes
    },
  } = useStaticQuery(graphql `
    query getOptions {
      allWp {
        nodes {
          subscribeForm {
            subscribeForm {
              subscribeFormTitle
              subscribeFormInputEmail
              subscribeFormFormDescription
              subscribeFormCaption
            }
          }
          aboutUsPromo {
            aboutUsPromo {
              aboutUsTitle
              aboutUsDescription
              aboutUsCaption
              aboutUsButton {
                title
                url
              }
            }
          }
          general {
            general {
              risksAndPreventionPage {
                risksAndPreventionPageLinkToArticle {
                  title
                  url
                }
              }
              relatedArticles {
                relatedArticlesViewAllButton {
                  title
                  url
                }
              }
              familyAndCaregiversPage {
                familyAndCaregiversPageLinkToArticle {
                  title
                  url
                }
              }
              libraryPage {
                libraryPageBackToGuideLink {
                  title
                  url
                }
              }
              nonCommercialBlock {
                nonCommercialBlockTitle
                nonCommercialBlockPosition
                nonCommercialBlockBlockquote
                nonCommercialBlockAuthor
                nonCommercialBlockImage {
                  altText
                  srcSet
                  sourceUrl
                }
              }
            }
          }
          header {
            header {
              donateButton {
                title
                url
              }
            }
          }
          footer {
            footer {
              copyrightText
              footerContactEmail
              footerDescription
              footerSocialsUrl {
                socialUrl
              }
            }
          }
        }
      }
    }
  `);

  return nodes[0];
};

export default useOptions;
