import React, { useEffect } from 'react';

import useCurrentSub from 'hooks/useCurrentSub';

import Footer from './Footer';
import Header from './Header';

const Layout = ({ children }) => {
  const category = useCurrentSub();

  useEffect(() => {
    document.body.className = category ?? '';
  }, [category]);

  return (
    <>
      <Header />
      <main>
        {children}
      </main>
      <Footer />
    </>
  )
}

export default Layout;
