import React from 'react';
import classnames from 'classnames';
import { useWindowWidth } from '@react-hook/window-size/throttled';

import Link from 'components/Link';

import Logo from 'assets/logos/logo-dark.inline.svg';

import * as styles from './styles.module.scss';

import HeaderDesktop from './Desktop';
import HeaderMobile from './Mobile';

const Header = () => {
  const windowWidth = useWindowWidth({ initialWidth: 1024 });

  return (
    <header className="fixed top-5 left-0 right-0 fluid-container z-50">
      <div
        className={classnames(
          'relative flex items-center px-4 py-3 sm:p-5',
          styles.wrapper,
        )}
      >
        <p className="w-48">
          <Link href="/">
            <Logo />
          </Link>
        </p>

        {windowWidth <= 1024 ? <HeaderMobile /> : <HeaderDesktop />}
      </div>
    </header>
  );
};

export default Header;
