import React from 'react';

import Navigation from '../Navigation';
import { ButtonExtraSmall } from 'components/ui/Button';

import useOptions from 'hooks/graphql/useOptions';

const HeaderDesktop = () => {
  const options = useOptions();

  const {
    header: { donateButton },
  } = options.header;

  return (
    <div className="flex-1 flex items-center justify-between">
      <div className="oc-small m-auto">
        <Navigation />
      </div>

      <div className="flex justify-end w-48">
        <ButtonExtraSmall href={donateButton.url} className={'btn--fill'}>
          {donateButton.title}
        </ButtonExtraSmall>
      </div>
    </div>
  );
};

export default HeaderDesktop;
