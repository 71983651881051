import {
  useStaticQuery,
  graphql
} from 'gatsby';

const allMenus = graphql`
  query {
    allWpMenu {
      nodes {
        name
        locations
        menuItems {
          nodes {
            label
            path
            order
            childItems {
              nodes {
                label
                path
                order
              }
            }
          }
        }
      }
    }
  }
`;

const useAllMenus = () => {
  const {
    allWpMenu: {
      nodes
    },
  } = useStaticQuery(allMenus);

  return nodes;
};

export default useAllMenus;
