import React from 'react';

import Icon from 'components/ui/Icon';
import Link from 'components/Link';

import useSEO from 'hooks/graphql/useSocials';

const ContactsFooter = ({ data }) => {
  const { footerContactEmail } = data;
  const seoDefaultWP = useSEO();

  return (
    <div className="lg:flex pb-14">
      <div className="">
        <p className="text-white opacity-40 uppercase pb-3">Contact us here</p>
        <p className="mb-16 sm:mb-0 underline text-2xl sm:text-4xl xl:text-6xl text-white hover:text-opacity-70">
          <a href={`mailto: ${footerContactEmail}`}>{footerContactEmail}</a>
        </p>
      </div>
      <div className="flex flex-col text-white uppercase lg:w-56 ml-auto">
        <p className="uppercase opacity-40 mb-6">follow us:</p>
        <ul className="flex space-x-5">
          {seoDefaultWP.social.map((link) => {
            return (
              <li
                key={link.url}
                className="border border-white rounded-full w-14 h-14 flex justify-center items-center"
              >
                <Link
                  target="_blank"
                  to={link.url}
                  className="transform hover:scale-110 hover:opacity-90"
                >
                  <Icon name={link.icon} className="w-9 h-9" />
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default ContactsFooter;
