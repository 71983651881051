import React from 'react';

import Details from './Details';
import Navigation from './Navigation';

const TopFooter = ({ data }) => (
  <div className="xl:flex pb-16 lg:pb-24">
    <div className="flex-none xl:pr-60 xl:max-w-xl pb-10 sm:pb-14 xl:pb-0">
      <Details data={data} />
    </div>

    <div className="flex-1">
      <Navigation />
    </div>
  </div>
);

export default TopFooter;
