import { useMemo } from 'react';
import { useLocation } from '@reach/router';

import useAllPages from 'hooks/graphql/useAllPages';
// import { getSectionFromStorage, setSectionToStorage, removeSectionFromStorage } from 'helpers/localStorage';

const useCurrentSub = () => {
  const { pathname, hash } = useLocation();

  const pages = useAllPages();

  const slug = useMemo(() => {
    const currentParrent = pages
      .filter((page) => !page.parentId && page.uri !== '/')
      .find(
        ({ slug }) =>
          new RegExp(`(^/${slug}$)|(^/${slug}/)`).test(pathname) ||
          new RegExp(`(^#${slug}$)`).test(hash),
      );

    if (pathname === '/') return null;

    if (new RegExp(`(^/guide-article)|(^/guide-post)`).test(pathname))
      return 'lung-cancer';

    // if (currentParrent) return currentParrent.slug;
    if (!currentParrent) return null;

    return currentParrent.slug;

    // return getSectionFromStorage();
  }, [pages, pathname, hash]);

  // useEffect(() => {
  //   if (slug) {
  //     setSectionToStorage(slug);
  //   } else {
  //     removeSectionFromStorage();
  //   }
  // }, [pathname, slug]);

  return slug;
};

export default useCurrentSub;
