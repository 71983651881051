import React from 'react';
import CapptooLogo from 'assets/logos/capptoo.inline.svg';

const BottomFooter = ({ data }) => {
  return (
    <div className="border-t border-white border-opacity-10 pt-14 pb-10">
      <div className="fluid-container">
        <div className="sm:flex justify-between">
          <div className="pt-16 sm:pt-0 sm:w-56 flex">
            <p className="text-white opacity-40 uppercase pb-5 mr-5 flex-none items-center">
              Brought to you by:
            </p>
            <p>
              <CapptooLogo />
            </p>
          </div>

          <div className="text-white opacity-40 uppercase">
            <p>{data}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BottomFooter;
