import React from 'react';

import TopFooter from './Top';
import ContactsFooter from './Contacts';
import BottomFooter from './Bottom';
// import useCurrentNav from 'hooks/useCurrentNav';

import useOptions from 'hooks/graphql/useOptions';

const Footer = () => {
  // const caurrentNavigation = useCurrentNav();
  // const navigation = caurrentNavigation.footer?.menuItems.nodes || null;
  // if (!navigation) return <></>;

  const options = useOptions();

  const {
    footer: {
      copyrightText,
      footerContactEmail,
      footerDescription,
      footerSocialsUrl,
    },
  } = options.footer;

  const contacts = {
    footerContactEmail,
    footerSocialsUrl,
  };

  return (
    <footer className={'bg-mine-shaft'}>
      <div className="fluid-container">
        <div className="pt-20 sm:pt-32">
          <TopFooter data={footerDescription} />
          <ContactsFooter data={contacts} />
        </div>
      </div>

      <BottomFooter data={copyrightText} />
    </footer>
  );
};

export default Footer;
