import React from 'react';
import classnames from 'classnames';

import Link from 'components/Link';

const ItemNavColumn = ({ link: { path, label }, className = '' }) => (
  <li key={path + label} className={classnames('mb-5', className)}>
    <Link href={path} className="oc-base text-white hover:text-opacity-70">
      {label}{' '}
      <sup>
        {label === 'Brain Cancer' || label === 'Kidney cancer'
          ? '(Coming Soon)'
          : null}
      </sup>
    </Link>
  </li>
);

export default ItemNavColumn;
