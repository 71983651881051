import { useStaticQuery, graphql } from 'gatsby';

const seoQuery = graphql`
  query getSeo {
    wp {
      seo {
        schema {
          siteName
          companyName
          companyLogo {
            sourceUrl
            title
          }
          logo {
            sourceUrl
            title
          }
          wordpressSiteName
        }
        openGraph {
          frontPage {
            description
            title
          }
          defaultImage {
            sourceUrl
          }
        }
        social {
          youTube {
            url
          }
          linkedIn {
            url
          }
          facebook {
            url
          }
          instagram {
            url
          }
          mySpace {
            url
          }
          pinterest {
            url
            metaTag
          }
          twitter {
            username
          }
          wikipedia {
            url
          }
        }
      }
    }
  }
`;

const useSEO = () => {
  const {
    wp: { seo },
  } = useStaticQuery(seoQuery);

  return {
    ...seo,
    social: Object.entries(seo.social)
      .filter(([, value]) => Object.values(value)[0])
      .map(([key, value]) => ({
        name: key,
        icon: `${key}`,
        url: value.url || `https://twitter/${value.username}`,
      })),
  };
};

export default useSEO;
