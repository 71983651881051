import React from 'react';
import Link from 'components/Link';

import useCurrentNav from 'hooks/useCurrentNav';
import useAllMenus from 'hooks/graphql/useAllMenus';

const Navigation = () => {
  const currentNavigation = useCurrentNav();
  const allNavs = useAllMenus();

  const globalHEaderMenu = allNavs.filter(
    (menu) => menu.name === 'Global header menu',
  )[0].menuItems.nodes;

  const navigation =
    currentNavigation.header?.menuItems.nodes || globalHEaderMenu;

  return (
    <div className="flex-shrink-0">
      <nav>
        <ul className="lg:flex space-y-5 text-center lg:text-left lg:space-y-0 lg:space-x-8">
          {navigation.map(({ label, path, order }) => (
            <li key={path + order}>
              <Link
                href={path}
                className="text-mine-shaft hover:text-opacity-70"
              >
                {label}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default Navigation;
