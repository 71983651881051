import { useMemo } from 'react';
import useCurrentSub from 'hooks/useCurrentSub';

import useAllMenus from 'hooks/graphql/useAllMenus';

const useCurrentNav = () => {
  const category = useCurrentSub();

  const allNavs = useAllMenus();

  const currentMenu = useMemo(
    () =>
      category
        ? allNavs.filter(({ locations }) => {
            const prepareCat = category.replace(/-/g, '_').toUpperCase();

            return locations.some((item) => item.includes(prepareCat));
          })
        : allNavs.filter(({ locations }) => {
            return locations.some((item) => item.includes('GATSBY'));
          }),
    [allNavs, category],
  );

  const data = {
    footer: useMemo(
      () =>
        currentMenu
          ? currentMenu.find((item) =>
              item.locations.some((location) => location.includes('FOOTER')),
            )
          : null,
      [currentMenu],
    ),

    header: useMemo(
      () =>
        currentMenu
          ? currentMenu.find((item) =>
              item.locations.some((location) => location.includes('HEADER')),
            )
          : null,
      [currentMenu],
    ),
  };

  return data;
};

export default useCurrentNav;
