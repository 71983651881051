import React, { useCallback, useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useLocation } from '@reach/router';

import Icon from 'components/ui/Icon';
import Navigation from '../Navigation';
import { Button } from 'components/ui/Button';

import useOptions from 'hooks/graphql/useOptions';

const HeaderMobile = () => {
  const [isMenuShow, setShowMenu] = useState(false);

  const location = useLocation();
  const options = useOptions();

  useEffect(() => {
    setShowMenu(false);
  }, [location]);

  const handleOpenMenu = useCallback(() => {
    setShowMenu((state) => !state);
  }, [setShowMenu]);

  const {
    header: { donateButton },
  } = options.header;

  return (
    <div className="ml-auto">
      <button
        className="flex justify-center items-center transform transition duration-300 hover:scale-110"
        onClick={handleOpenMenu}
      >
        <Icon name="burger" className="w-9 h-9" />
      </button>

      <CSSTransition
        in={isMenuShow}
        timeout={800}
        classNames={{
          enter: 'animate__animated animate__fast',
          enterActive: 'animate__fadeInDown',
          leave: 'animate__animated animate__fast',
          leaveActive: 'animate__fadeOutUp',
        }}
        unmountOnExit
      >
        <div className="absolute top-full left-0 right-0 pt-5">
          <div className="bg-white rounded-3xl shadow-md p-10">
            <div className="oc-lead-1 pb-10 lg:pb-0 lg:oc-small lg:m-auto">
              <Navigation />
            </div>

            <div className="flex justify-center">
              <Button href={donateButton.url} className="btn--fill w-full">
                {donateButton.title}
              </Button>
            </div>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default HeaderMobile;
